import moment from "moment";

export function fetchPayLoad(finalForm, generatedS3Key, IsValidateBill) {
  return {
    CorporationID: finalForm["property"]?.corporationID?.replace("0x", ""),
    Path1: encodeURIComponent(generatedS3Key),
    VendorID: finalForm.vendor?.id?.replace("0x", ""),
    ContractID: finalForm["contract"]?.id,
    BillDate: moment(finalForm["invoice_date"]).format("MM/DD/YYYY"),
    InvoiceNumber: finalForm.invoice_number?.toString()?.trim(),
    OptionalDueDate: finalForm["payment_due_date"],
    Note: finalForm.memo,
    ApprovalComment: "",
    BooksDate: moment(
      finalForm["isBooksDateEnabled"]
        ? finalForm["books_date"]
        : finalForm["invoice_date"]
    ).format("MM/DD/YYYY"),
    PaymentMethodID: finalForm["payment_type"]?.id,
    IsValidateBill: IsValidateBill,
    IsApprove: finalForm["IsApprove"],
    Amount: Number(finalForm.invoice_amount),
    TransactionDetails: finalForm["splitDetails"]?.map((item) => ({
      ...item,
      Amount: Number(item["Amount"]),
    })),
  };
}
