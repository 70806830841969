import { createTheme } from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
// uploadBtn: createColor("#6d7de0"),
const theme = createTheme({
  palette: {
    customPrimary: createColor("#67E0BC"),
    customSuccess: createColor("#c6e3c2"),
    customSecondary: createColor("#bafbdf"),
    customWaring: createColor("#fafaad"),
    customError: createColor("#f4d4d7"),
    customAction: createColor("#a6e0fa"),

    actionText: createColor("#003DFE"),
    actionSecondaryText: createColor("#008080"),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
        },
      },
    },
  },
  typography: {
    fontFamily: "'Poppins Medium', 'Poppins Regular', 'Poppins', sans-serif",
  },
});

export default theme;
