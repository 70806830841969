import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";
import React, { act, useEffect, useState } from "react";
import {
  BACKEND_URL,
  CONSUMER_URL,
  NIMBLE_URL,
} from "../../../helpers/variables";
import Property from "./Fields/Property";
import Vendor from "./Fields/Vendor";
import Contract from "./Fields/Contract";
import PaymentType from "./Fields/PaymentType";
import { enqueueSnackbar } from "notistack";
import InvoiceNumber from "./Fields/InvoiceNumber";
import InvoiceDate from "./Fields/InvoiceDate";
import InvoiceAmount from "./Fields/InvoiceAmount";
import PaymentDueDate from "./Fields/PaymentDueDate";
import BooksDate from "./Fields/BooksDate";
import { LineItems } from "./LineItems/LineItems";
import moment from "moment";
import { fetchPayLoad } from "./payload.js";
import zIndex from "@mui/material/styles/zIndex";
import { billEntrySchema } from "./validation/schema.js";
import { ErrorDialog } from "./Confirms/ErrorDialog.jsx";
import { useLoaderData } from "react-router-dom";
import currency from "currency.js";
import { confirmByUser } from "./validation/confirm.js";
import _ from "lodash";
import { usePages } from "../../Contexts/InvoicePages.jsx";
import { BooksDateScheme } from "./validation/customScheme.js";
import logo from "../../../images/logo_white.png";

const GUTTER = 2;
const DATE_FORMAT = "MM/DD/YYYY";

const FormPanel = ({ bill, invoice }) => {
  const { user, client_token, client_url, user_id } = useLoaderData();

  const { pages, updatePages, fileLoadingFailed, setPages } = usePages();
  const {
    checked_pages,
    blocked_pages,
    split_pages,
    invoice_pages,
    no_of_pages,
  } = pages;

  const { _id, file_table_id, s3_key, bill_status } = bill;

  let {
    invoice_date = "",
    payment_due_date = "",
    invoice_number = "",
    invoice_amount = "",
    line_items = [],
    page_numbers = [],
  } = invoice || {};

  //______________________INVOICE DATE
  invoice_date = moment(invoice_date);
  let isValidInvoiceDate = invoice_date.isValid();

  invoice_date = (isValidInvoiceDate && invoice_date.format(DATE_FORMAT)) || "";

  //______________________DUE DATE
  payment_due_date = moment(payment_due_date);
  let isValidPaymentDueDate = payment_due_date.isValid();

  function calculatePaymentDueDate() {
    payment_due_date = "";
    let dueFromString = parseInt(payment_due_date.match(/\d+/));
    if (!isNaN(dueFromString) && isValidInvoiceDate) {
      payment_due_date = invoice_date
        .add(dueFromString, "days")
        .format(DATE_FORMAT);
    }
    return payment_due_date;
  }

  payment_due_date = isValidPaymentDueDate
    ? payment_due_date.format(DATE_FORMAT)
    : calculatePaymentDueDate();

  const [reviewForm, setReviewForm] = useState({
    property: null,
    vendor: null,
    contract: null,
    payment_type: "",
    bank_account: null,
    check_number: "",
    invoice_number,
    invoice_date: invoice_date,
    invoice_amount: currency(invoice_amount).value,
    payment_due_date: payment_due_date,
    memo: "",
    books_date: invoice_date,
    isBooksDateEnabled: false,
    IsApprove: false,
    splitDetails: [],
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [accountsList, setAccountsList] = useState([]);

  const [openReviewPanel, setOpenReviewPanel] = useState(false);
  const [generatedS3Key, setGeneratedS3Key] = useState(null);

  const { property } = reviewForm;

  const HEADERS = Object.freeze({
    "Content-Type": "application/json",
    token: client_token,
    Domain: client_url,
    UserID: user_id,
  });

  useEffect(() => {
    getAccountsList();
  }, [property]);

  useEffect(() => {
    setOpenReviewPanel(null);
  }, [checked_pages]);

  useEffect(() => {
    if (openReviewPanel) {
      generateS3Key();
    }
  }, [openReviewPanel]);

  async function generateS3Key() {
    setGeneratedS3Key(null);
    if (no_of_pages.length === checked_pages.length) {
      setGeneratedS3Key(s3_key);
    } else {
      enqueueSnackbar("Creating file for selected pages");
      try {
        let res = await fetch(`${BACKEND_URL}/generate_pdf`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ file_table_id, checked_pages }),
        });
        let data = await res.text();
        if (res.ok) {
          setGeneratedS3Key(data);
        } else {
          enqueueSnackbar(data, { variant: "error" });
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    }
  }

  async function addToQueue() {
    setIsFormSubmitting(true);
    try {
      let res = await fetch(
        `${CONSUMER_URL}/add_to_queue?file_table_id=${file_table_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checked_pages),
        }
      );
      if (res.ok) {
        let data = await res.json();
        if (data.length > 0) {
          await updateBill(2, data, "splitted");
        } else {
          enqueueSnackbar("Please proceed manually", { variant: "warning" });
        }
      } else {
        let text = await res.text();
        enqueueSnackbar(text, { variant: "warning" });
      }
    } catch (err) {
      enqueueSnackbar(`Error ${err}`, { variant: "error" });
    } finally {
      setIsFormSubmitting(false);
    }
  }
  async function getAccountsList() {
    if (property) {
      setAccountsList([]);
      const CorporationID = property.corporationID;
      try {
        let res = await fetch(`${NIMBLE_URL}/GetAccountsList`, {
          method: "POST",
          headers: HEADERS,
          body: JSON.stringify({ CorporationID }),
        });
        let data = await res.json();
        setAccountsList(data["account"]);
      } catch (error) {
        enqueueSnackbar(`Error fetching accounts type list: ${error}`, {
          variant: "error",
        });
      }
    }
  }

  /**
   * @param {Event} e
   */
  function updateForm(e) {
    let key = e.target.name;
    let value = e.target.value;
    setReviewForm((prev) => ({ ...prev, [key]: value }));
  }

  /**
   * @param {ClipboardEvent} e
   */
  function parseDateFromClipboard(e) {
    e.preventDefault();
    let key = e.target.name;
    let value = e.clipboardData.getData("Text");

    if (moment(value).isValid()) {
      let date = moment(value).format("MM/DD/YYYY");
      setReviewForm((prev) => ({ ...prev, [key]: date }));
    } else {
      setReviewForm((prev) => ({ ...prev, [key]: value }));
    }
  }

  /**
   * @param {Boolean} IsValidateBill
   */
  async function formSubmit(IsValidateBill = false) {
    setIsFormSubmitting(true);

    try {
      await billEntrySchema.validate(reviewForm);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "warning" });
      setIsFormSubmitting(false);
      return;
    }

    try {
      await confirmByUser.validate(reviewForm);
    } catch (err) {
      setIsFormSubmitting(false);
      return;
    }

    if (reviewForm["isBooksDateEnabled"]) {
      try {
        await BooksDateScheme.validate(reviewForm);
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "warning" });
        setIsFormSubmitting(false);
        return;
      }
    }

    const payload = fetchPayLoad(reviewForm, generatedS3Key, IsValidateBill);

    try {
      const res = await fetch(`${NIMBLE_URL}/BillEntrySave`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: client_token,
          Domain: client_url,
          UserID: user_id,
        },
        body: JSON.stringify(payload),
      });

      let nimbleResponce = await res.json();

      let message = `Nimble API | ${nimbleResponce["message"]}`;
      let status = nimbleResponce["status"];

      switch (status) {
        case 100: // Saved successfully.
          await handleSuccessResponse(nimbleResponce, payload);
          await updateBill(2, checked_pages, "locked");
          enqueueSnackbar(message, { variant: "info" });
          break;

        case 402: // Validation error. You can get validation text in message.
        case 403: // API exception. Which is from catch block.
          enqueueSnackbar(message, { variant: "error" });
          break;

        case 404: // Another bill with same invoice number without attachment.
        case 405: // Entry exists with same date and amount
        case 406: // Another bill with same invoice number with attachment.
          const action = await ErrorDialog(message, status);
          switch (action) {
            case "attach_to_exists":
              await attachBillToExist(res["id"]);
              break;

            case "send_as_new":
              await formSubmit(true);
              break;

            case "duplicate":
              await updateBill(-1, checked_pages, "locked");
              break;
          }
          break;
      }
    } catch (err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: "error" });
    }

    setIsFormSubmitting(false);
  }

  /**
   * Updates the status of a bill.
   *
   * @param {number} status - The new status to set for the bill. This should be a number indicating the updated status.
   * @param {number[]} checked_pages - Pages to update
   * @param {'splitted' | 'locked'} type - The type of update to perform. This should be either "splitted" or "locked".
   * @returns {Promise<void>}
   */

  async function updateBill(status, pages, type) {
    let update_bill = false;
    let related_pages = [];
    let action_performed_by = user;

    if (bill_status === 0) {
      update_bill = page_numbers.every((i) => checked_pages.includes(i));
      related_pages = _.difference(checked_pages, page_numbers);
    } else {
      let all_pages = _.union(
        checked_pages,
        blocked_pages,
        split_pages,
        invoice_pages
      );
      update_bill = _.isEqual(_.sortBy(all_pages), _.sortBy(no_of_pages));
    }
    let payload = {
      bill_id: _id,
      type,
      status,
      pages,
      file_table_id,
      update_bill,
      related_pages,
      action_performed_by,
    };
    let res = await fetch(`${BACKEND_URL}/process_bill_update`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    if (res.status === 201) {
      setPages((prev) => ({
        ...prev,
        [type === "locked" ? "blocked_pages" : "split_pages"]: pages,
        checked_pages: [],
      }));
      setOpenReviewPanel(false);
    }
  }

  async function attachBillToExist(BillID) {
    setIsFormSubmitting(true);
    let CorporationID = property["corporationID"];
    let body = {
      Domain: client_url,
      AttachmentInfo: [
        {
          BillID,
          CorporationID: CorporationID.replace("0x", ""),
          Path: decodeURI(generatedS3Key),
        },
      ],
    };

    try {
      let res = await fetch(`${NIMBLE_URL}/GetAccountsList`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: client_token,
          Domain: client_url,
          UserID: user_id,
        },
        body: JSON.stringify(body),
      });
      let nimbleResponce = await res.json();

      let message = `Nimble API | ${nimbleResponce["message"]}`;
      let status = nimbleResponce["status"];
      if (status === 100) {
        await updateBill(3, checked_pages, "locked");
        handleSuccessResponse(nimbleResponce, body);
      }
      enqueueSnackbar(message, { variant: "warning" });
    } catch (error) {
      enqueueSnackbar(`Error fetching accounts type list: ${error}`, {
        variant: "error",
      });
    }

    setIsFormSubmitting(false);
  }

  async function handleSuccessResponse(nimbleResponce, payload) {}

  return (
    <Box border={1} borderColor={"divider"} height={"100%"} overflow={"auto"}>
      {!openReviewPanel ? (
        <Stack
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          <Box
            sx={{ opacity: 0.4, userSelect: "none" }}
            onDragStart={(e) => e.preventDefault()}
            component={"img"}
            src={logo}
            width={"40%"}
          />

          <ButtonGroup
            disabled={checked_pages.length === 0 && !fileLoadingFailed}
          >
            <Button onClick={() => setOpenReviewPanel(true)}>Open</Button>
            {bill_status !== 0 && (
              <Button onClick={addToQueue}>Add to queue</Button>
            )}
            {/* <Button
              onClick={async () => {
                setIsFormSubmitting(true);
                await updateBill(-1, checked_pages, "locked");
                setIsFormSubmitting(false);
              }}
            >
              Delete
            </Button> */}
          </ButtonGroup>
        </Stack>
      ) : (
        <>
          <Stack gap={GUTTER} p={GUTTER}>
            <Property
              reviewForm={reviewForm}
              setReviewForm={setReviewForm}
              bill={bill}
            />

            <Vendor
              reviewForm={reviewForm}
              setReviewForm={setReviewForm}
              HEADERS={HEADERS}
              invoice={invoice}
            />

            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(2, 1fr)"}
              gap={GUTTER}
            >
              <Contract
                reviewForm={reviewForm}
                setReviewForm={setReviewForm}
                HEADERS={HEADERS}
              />

              <PaymentType
                HEADERS={HEADERS}
                reviewForm={reviewForm}
                setReviewForm={setReviewForm}
              />
            </Box>

            <Divider />

            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(2, 1fr)"}
              gap={GUTTER}
            >
              <InvoiceNumber
                reviewForm={reviewForm}
                setReviewForm={setReviewForm}
              />

              <InvoiceDate
                value={reviewForm["invoice_date"]}
                setReviewForm={setReviewForm}
                onPaste={parseDateFromClipboard}
              />

              <InvoiceAmount
                value={reviewForm["invoice_amount"]}
                onChange={updateForm}
              />
              <PaymentDueDate
                reviewForm={reviewForm}
                setReviewForm={setReviewForm}
                onPaste={parseDateFromClipboard}
              />

              <Box />

              <BooksDate
                onChange={updateForm}
                onPaste={parseDateFromClipboard}
                HEADERS={HEADERS}
                reviewForm={reviewForm}
                setReviewForm={setReviewForm}
              />
            </Box>

            <Divider />

            <TextField
              placeholder="Memo"
              multiline
              value={reviewForm["memo"]}
              name="memo"
              onChange={updateForm}
            />

            <Divider />

            <LineItems
              reviewForm={reviewForm}
              setReviewForm={setReviewForm}
              invoice={invoice}
              accounts={accountsList}
              HEADERS={HEADERS}
            />
          </Stack>

          <Toolbar
            sx={{
              borderTop: 1,
              borderColor: "divider",
              gap: 2,
              justifyContent: "center",
              position: "sticky",
              bottom: 0,
              bgcolor: "white",
              zIndex: 10,
            }}
          >
            <ButtonGroup disabled={!generatedS3Key}>
              <Button variant="contained" onClick={() => formSubmit()}>
                Send
              </Button>
            </ButtonGroup>
          </Toolbar>
        </>
      )}
      <Backdrop open={isFormSubmitting} sx={{ zIndex: zIndex.tooltip + 1 }}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default FormPanel;
