import React, { useEffect, useState } from "react";
import { BACKEND_URL, NIMBLE_URL } from "../../../../helpers/variables";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { validateVendor } from "../validation/individual";

const Vendor = ({ reviewForm, setReviewForm, HEADERS, invoice }) => {
  const { property, contract } = reviewForm;
  const [vendorList, setVendorList] = useState([]);

  useEffect(() => {
    loadVendorData();
  }, [property]);

  async function loadVendorData() {
    setVendorList([]);
    if (!property) return;
    let vendors = await getVendorList();
    if (vendors.length === 0) return;
    await getVendor(vendors);
    setVendorList(vendors);
  }

  async function getVendorList() {
    const CorporationID = property.corporationID;
    try {
      let res = await fetch(`${NIMBLE_URL}/VECOList`, {
        method: "POST",
        headers: HEADERS,
        body: JSON.stringify({ CorporationID }),
      });
      let data = await res.json();
      return data;
    } catch (error) {
      enqueueSnackbar(`Error fetching vendors from Nimble: ${error}`, {
        variant: "error",
      });
    }
    return [];
  }

  async function getVendor(vendors) {
    let { vendor_name = "", vendor_address = "" } = invoice || {};

    if (vendor_name.trim() || vendor_address.trim()) {
      let payload = {
        vendors,
        vendor_name,
        vendor_address,
      };
      try {
        let res = await fetch(`${BACKEND_URL}/get_vendor`, {
          method: "POST",
          body: JSON.stringify(payload),
        });

        let status = res.status;
        if (status === 200) {
          let vendor = await res.json();
          updateVendor(vendor);
          return;
        } else if (status === 404) {
          let errorMessage = await res.text();
          enqueueSnackbar(errorMessage, { variant: "warning" });
        } else {
          enqueueSnackbar(`Error: ${status} - ${res.statusText}`, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(`Error fetching vendor: ${error}`, {
          variant: "error",
        });
      }
      updateVendor(null);
    }
  }

  function updateVendor(newVendor) {
    setReviewForm((prev) => ({ ...prev, vendor: newVendor }));
  }

  function addTypeDescriptions(data) {
    data.sort((a, b) => a.type - b.type);
    data.forEach((i) => {
      let type = i["type"];
      // 0 Customer
      // 1 Vendor
      // 2 Employee
      // 13 Others
      switch (type) {
        case 0:
          i["typeDescription"] = "Customers";
          break;
        case 1:
          i["typeDescription"] = "Vendors";
          break;
        case 2:
          i["typeDescription"] = "Employee's";
          break;
        case 13:
          i["typeDescription"] = "Others";
          break;
      }
    });
    return data.filter((i) => i["type"] === 1);
  }

  const vendor = reviewForm["vendor"];
  const validate = validateVendor(vendor);

  return vendorList.length === 0 ? (
    <Skeleton variant="rounded" animation="wave" width={"100%"}>
      <TextField size="small" fullWidth />
    </Skeleton>
  ) : (
    <Autocomplete
      value={vendor}
      size="small"
      options={addTypeDescriptions(vendorList)}
      getOptionLabel={(option) => option.name}
      onChange={(_, v) => updateVendor(v)}
      getOptionDisabled={(option) => option["status"] !== "1"}
      groupBy={(option) => option.typeDescription}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Vendors"
          error={Boolean(validate)}
          helperText={
            validate || contract?.["address1"] || vendor?.["address1"]
          }
        />
      )}
    />
  );
};

export default Vendor;
